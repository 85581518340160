
window.calcula_saldos_tabla =  function(){
    



    var total = 0;
    var total_pi = 0;
    var total_ce = 0;
    var total_efectivo = 0;
    
    var allData = document.querySelectorAll('.sumar_seleccionados:checked');
     
    for (var i = 0; i < allData.length; i++) {     
      c = allData[i].getAttribute('data-valor');
      total+=parseFloat(c);      
      if(allData[i].getAttribute('data-cuenta')=="1")total_ce+=parseFloat(c); 
      if(allData[i].getAttribute('data-cuenta')=="2")total_pi+=parseFloat(c);      
      if(allData[i].getAttribute('data-cuenta')=="3")total_efectivo+=parseFloat(c);      
           
    }
    var num = "$"+Number((total).toFixed(2)).toLocaleString()
    var numce = "$"+Number((total_ce).toFixed(2)).toLocaleString()
    var numpi = "$"+Number((total_pi).toFixed(2)).toLocaleString()
    var numefectivo = "$"+Number((total_efectivo).toFixed(2)).toLocaleString()

    var numce_saldo = "$"+Number((cuenta_ce-total_ce).toFixed(2)).toLocaleString()
    var numpi_saldo = "$"+Number((cuenta_pi-total_pi).toFixed(2)).toLocaleString()    

    var num_saldo = "$"+Number((cuenta_ce-total_ce+cuenta_pi-total_pi).toFixed(2)).toLocaleString()    

    console.log(numefectivo);
    document.getElementById("cantidad").innerHTML = allData.length; 
    document.getElementById("total").innerHTML = num; 
    document.getElementById("total_ce").innerHTML = numce; 
    document.getElementById("total_pi").innerHTML = numpi; 
    document.getElementById("total_efectivoCE").innerHTML = numefectivo; 

    document.getElementById("saldo_ce").innerHTML = numce_saldo; 
    document.getElementById("saldo_pi").innerHTML = numpi_saldo; 
    document.getElementById("saldo").innerHTML = num_saldo; 

}

$(document).on("turbolinks:load", function () {
    
    $( ".boton_expandir" ).click(function() {
      $(this).toggleClass('fa-compress fa-expand');
      elemento_id = ($(this).data("elemento"));
      $("#"+elemento_id).toggle();
    });

});